<template>
<div class="fill" style="overflow: hidden">
    <div class="fill flex main-container" v-if="local">

    <!-- Left Menu -->
    <transition enter-active-class="animated slideInLeft faster" leave-active-class="animated slideOutLeft">
    <div class="left-menu l-menu" v-show="!mobile && !tablet">
        <div class="ta-center l-local-logo" v-if="local.logotype">
            <img :src="Util.img(local.logotype, 'logos')" style="width: 100px; margin: 10px 0px" class="logo">
        </div>
        <div class="ta-center s1 l-local-name">{{local.name}}</div>

        <div class="separator l-separator l-separator-top"></div>
        <div class="flex-fill flex column l-scroll-container" style="overflow-y: auto; overflow-x: hidden; padding-bottom: 20px">
            <div class="s2 ta-left l-title l-title-menu">Menú</div>
            <div :class="`c2 s2 ta-left txt-hover l-entry l-menu-section-title l-menu-section-${section.title}`" style="margin-top: 10px" v-for="section in filteredMenu" :key="section.title" v-show="section.items.length" @click="$refs.menuItems.GoToSection(section)" :title="`Ir a la sección ${section.title} del menu`">{{section.title}}</div>
            
            <div v-if="itemsWithAnticipation.length" class="l-menu-section-reservation">
                <div class="separator small l-entry l-separator l-separator-menu"></div>
                <div class="c2 s2 flex align-center txt-hover" @click="anticipationFilter = !anticipationFilter">
                    <i :class="`icon icon-square${anticipationFilter ? '-check cy' : ''}`"></i>
                    <span style="margin-left: 5px" :class="anticipationFilter ? 'cy' : ''">Productos con reserva</span>
                </div>
            </div>

            <div class="flex column hide-virtual-menu">
                <div class="s2 ta-left l-title l-title-info" style="margin-top: 20px">Información</div>
                <div class="c2 s2 ta-left txt-hover l-entry " style="margin-top: 10px" @click="Util.Open(`https://maps.google.com/?q=${local.latitude},${local.longitude}`)" :title="`Abrir ubicación de ${local.name} en Google Maps`"><i class="icon icon-map"></i> Ubicación</div>
                <div v-if="ecommerceType == 'RESTAURANT'" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px" @click="OpenModalSchedule()" :title="`Ver horarios disponibles de ${local.name}`"><i class="icon icon-clock"></i> Horarios</div>
                <div v-if="local.phone" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px" @click="OpenContact(local.phone)" :title="`Contactar a ${local.name}`"><i class="icon icon-phone"></i> Contacto</div>
                <div v-if="Util.ig(local.instagram)" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px; text-wrap: nowrap;"  @click="Util.Open('https://instagram.com/'+Util.ig(local.instagram))" :title="`Ver instagram de ${local.name}`"><i class="icon icon-instagram"></i> {{Util.ig(local.instagram)}}</div>
            </div>
        </div>
        <div class="separator l-separator l-separator-bottom"></div>
        <a href="https://influyeapp.cl" target="_blank" class="ta-left s3 txt-hover l-powered-by">Powered by Influye</a>
    </div>
    </transition>

    <!-- Center -->
    <div class="center-content ofy bg" ref="panelCenter" id="menu-container">
        <div>

            <!--banner -->
            <div class="flex column relative top-banner c-banner" v-show="(mobile || tablet) || (!mobile && !tablet && banner)" :style="{height: mobile ? '150px' : '200px', 'background-image': banner ? `url('${Util.img(banner, 'banners')}` : '', 'background-size': 'cover', 'background-position': 'center center'}">
                <div :class="`fill flex justify-center align-center ${!banner ? 'left-s bg' : ''}`">
                    <img v-if="!banner" :src="Util.img(local.logotype, 'logos')" style="width: 100px; margin-right: 20px" class="logo c-banner-image">
                    <div class="flex column c-banner-content">
                        <div v-show="mobile && !banner" class="ta-left c1 s1 c-banner-local-name" style="margin-bottom: 5px">{{local.name}}</div>
                        <div class="flex absolute" style="left: 10px; right: 10px; bottom: 10px; justify-content: end;">
                            <div v-show="false" class="btn relative flex fc c-banner-search-bar flex-fill" id="banner-search-bar" style="padding: 0px; height: 47px;">
                                <i class="icon icon-search c2 s2 absolute" style="transform: scale(1); margin: 0; left: 12px; top: 12px; font-size: 20px; pointer-events: none"></i>
                                <input v-model="searchItem" type="text" class="s2 c1" :style="{'flex-shrink': 0, width: '100%', padding: '15px', 'padding-left': '40px'}" placeholder="Buscar..." title="Buscar items del menú">
                                <i v-show="searchItem" class="icon icon-x absolute btn-hover c2" style="right: 10px; font-size: 25px; top: 10px; transform: scale(1);" @click="searchItem = ''"></i>
                            </div>
                            <div v-show="mobile" :class="`flex ${!banner ? 'c2' : 'c1 btn left-s'} c-banner-actions`" :style="!banner ? {'font-size': '22px', 'margin-left': '10px', 'flex-shrink': '0'} : {'font-size': '12px', 'padding': '10px', 'margin-left': '10px', 'flex-shrink': '0'}">
                                <i v-if="local.address" @click="Util.Open(`https://maps.google.com/?q=${local.latitude},${local.longitude}`)" class="icon icon-map icon-hover" style="margin-right: 10px" title="Ubicación"></i>
                                <i v-if="ecommerceType == 'RESTAURANT'" @click="modalSchedule = true" class="icon icon-clock icon-hover" style="margin-right: 10px" title="Horarios"></i>
                                <i v-if="local.phone" @click="OpenContact(local.phone)" class="icon icon-phone icon-hover" style="margin-right: 10px" title="Contactar"></i>
                                <i v-if="Util.ig(local.instagram)" @click="Util.Open('https://instagram.com/'+Util.ig(local.instagram))" class="icon icon-instagram icon-hover" title="Instagram"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <swiper v-if="carousel && carousel.length" class="swiper c-carousel" :options="{
                loop: true,
                grabCursor: true,
                autoplay: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            /*
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
            */
            }"
            style="cursor: grab">
                <swiper-slide v-for="entry in carousel" :key="entry.image" class="c-carousel-slide">
                    <img class="c-carousel-image" :src="Util.img(entry.image, 'banners')" alt="" style="width: 100%">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <!--
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
                -->
            </swiper>

            <!-- @start Instagram Images 
            <div id="panelInstagram">
                <div class="ig-container">
                    <a v-for="ig in instagramImages" :key="ig.image" target="_blank" :href="ig.url" title="Ir al Instagram">
                        <img :src="ig.image" width="200">
                    </a>
                </div>
            </div>
             @end Instagram Images -->

            <div class="btn relative flex fc c-search-bar" id="search-bar" :style="{overflow: 'none', padding: '0px', margin: mobile ? '0px 5px' : '10px 10px 0px'}">
                <i class="icon icon-search c2 s2 absolute" style="transform: scale(1); margin: 0; left: 12px; top: 12px; font-size: 20px; pointer-events: none"></i>
                <input v-model="searchItem" @focus="searchFocused=true" @blur="searchFocused=false" type="text" class="s2 c1" :style="{'flex-shrink': 0, width: '100%', 'max-width': (tablet || mobile) && (!searchItem && !searchFocused) ? '20px' : '', padding: '15px', 'padding-left': '40px'}" placeholder="Buscar..." title="Buscar items del menú">
                <i v-show="searchItem" class="icon icon-x absolute btn-hover c2" style="right: 10px; font-size: 25px; top: 10px; transform: scale(1);" @click="searchItem = ''"></i>
                <div class="flex align-center flex-fill c-menu-section-container" style="flex-wrap: nowrap; overflow: auto;" v-show="mobile || tablet">
                    <div :class="`c2 s2 txt-hover c-menu-section-entry c-menu-section-entry-${section.title.replace(/ /g, '')}`" style="padding-right: 10px; flex-shrink: 0" v-for="section in filteredMenu" :key="section.title" v-show="section.items.length" @click="$refs.menuItems.GoToSection(section)" :title="`Ir a la sección ${section.title} del menú`">{{section.title}}</div>
                    <div v-if="itemsWithAnticipation.length" class="unshrink flex align-center c-menu-section-entry c-menu-section-entry-reservation" style="margin-right: -10px">
                        <div class="separator" style="margin: 0px; width: 22px; transform: rotate(90deg) translateY(5px); opacity: 0.3"></div>
                        <div class="c2 s2 flex align-center txt-hover" style="padding-right: 10px" @click="anticipationFilter = !anticipationFilter">
                            <i :class="`icon icon-square${anticipationFilter ? '-check cy' : ''}`" style="transform: scale(1)"></i>
                            <span style="margin-left: 5px" :class="anticipationFilter ? 'cy' : ''">Productos con reserva</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="false" class="btn relative flex fc c-sections-list" :style="{margin: mobile ? '10px 5px 0px' : '10px 10px 0px', padding: '15px'}">
                <div class="flex align-center flex-fill c-menu-section-container" style="flex-wrap: nowrap; overflow: auto;">
                    <div :class="`c2 s2 txt-hover c-menu-section-entry c-menu-section-entry-${section.title.replace(/ /g, '')}`" style="padding-right: 10px; flex-shrink: 0" v-for="section in filteredMenu" :key="section.title" v-show="section.items.length" @click="$refs.menuItems.GoToSection(section)" :title="`Ir a la sección ${section.title} del menú`">{{section.title}}</div>
                    <div v-if="itemsWithAnticipation.length" class="unshrink flex align-center c-menu-section-entry c-menu-section-entry-reservation" style="margin-right: -10px">
                        <div class="separator" style="margin: 0px; width: 22px; transform: rotate(90deg) translateY(5px); opacity: 0.3"></div>
                        <div class="c2 s2 flex align-center txt-hover" style="padding-right: 10px" @click="anticipationFilter = !anticipationFilter">
                            <i :class="`icon icon-square${anticipationFilter ? '-check cy' : ''}`" style="transform: scale(1)"></i>
                            <span style="margin-left: 5px" :class="anticipationFilter ? 'cy' : ''">Productos con reserva</span>
                        </div>
                    </div>
                </div>
            </div>

            <menu-items ref="menuItems"
            :menu="filteredMenu" 
            :search="searchItem" 
            :analytics="$refs.analytics" 
            :canOpenItems="false"
            :canAddItems="false"
            :itemPriceDisplay="itemPriceDisplay"
            :itemImageSize="itemImageSize"
            :behaviour="menuDisplay"
            @ClearSearch="searchItem=''"
            @OnClickItem="itemDetails ? OpenModalItem($event) : null"></menu-items>

        </div>
    </div>

    <loading-screen :loading="loading"></loading-screen>
    <analytics-controller ref="analytics" :dictItems="dictItems" :categories="categories"></analytics-controller>

    </div>

    <modal v-model="modalItem" 
        :title="modalItem ? modalItem.title : null" 
        :desktopWidth="null" 
        :desktopHeight="null" 
        class="modal-item"
        @OnCloseModal="Util.ClearLink()">
        <div :class="`ofy flex-fill flex ${mobile ? 'column' : ''}`" v-if="modalItem">
            <div v-show="!mobile && itemImageSize == 'DEFAULT'" class="it-item-image-default" :style="{'width': '40%', 'min-width': '40%', height: mobile ? '200px' : '100%', 'background-image': `url('${Util.img(modalItem.image, 'products')}')`, 'background-size': 'cover', 'background-position': 'center'}"></div>
            <div class="ofy flex-fill flex column relative it-item-container" style="padding: 20px; overflow-x: hidden">

                <img v-show="mobile && itemImageSize == 'DEFAULT'" :src="Util.img(modalItem.image, 'products')" class="it-image it-image-default" style="width: calc(100% + 40px); margin: -20px 0px 20px -20px">

                <div v-if="modalItem.description || itemImageSize == 'SMALL'" class="it-info">
                    <div class="flex it-item-info">
                        <img v-if="itemImageSize == 'SMALL'" :src="Util.img(modalItem.image, 'products')" class="logo it-image it-image-small" style="margin-right: 10px; width: 50px; height: 50px">
                        <div v-if="modalItem.description" class="c1 s3 it-item-description">{{modalItem.description}}</div>
                    </div>
                    <div class="separator"></div>
                </div>
                
                <div v-for="(item, indexItem) in (modalItem.type == 0 ? [modalItem] : modalItem.products)" class="flex column it-groups-container" style="gap: 20px" :key="`item-${indexItem}`">

                    <div class="flex column it-subitem" v-if="modalItem.type == 1">
                        <div class="separator" v-if="indexItem > 0"></div>
                        <div class="flex">
                            <img :src="Util.img(item.image, 'products')" class="logo it-image it-subitem-image" style="margin-right: 10px; width: 50px; height: 50px">
                            <div class="flex column it-subitem-info">
                                <div class="s1 c1 it-subitem-title">{{item.title}}</div>
                                <div v-if="item.description" class="c2 s3 it-subitem-description">{{item.description}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="flex column it-group" v-for="(group, indexGroup) in item.modifiers" :key="`group-${indexGroup}`">
                        <div class="s1 c1 it-group-title" style="margin-bottom: 10px">{{group.title}}</div>

                        <div class="flex column it-modifiers-container" style="gap: 5px">
                            <div class="flex it-modifier" v-for="(modifier, indexModifier) in group.modifiers" :key="`mod-${indexModifier}`">
                                <div class="flex-fill flex column it-modifier-info">
                                    <div class="s2 c1 it-modifier-title">{{modifier.title}}</div>
                                    <div class="s3 c2 it-modifier-description">{{modifier.description}}</div>
                                </div>
                                <div class="s2 c1 it-modifier-price">{{Util.Price(modifier.price)}}</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        
    </modal>

</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import "swiper/css/swiper.min.css";
import LoadingScreen from './common/LoadingScreen.vue';
import ModalSchedule from './common/ModalSchedule.vue';
import ModalMap from './common/ModalMap.vue';
import AnalyticsController from './common/AnalyticsController.vue';
import ModalCoupon from './common/ModalCoupon.vue';
import ModalTip from './common/ModalTip.vue';
import LeftMenu from './common/LeftMenu.vue';
import MenuItems from './common/MenuItems.vue';

var moment = require('moment');
export default {
    components: {LoadingScreen, Swiper, SwiperSlide, ModalSchedule, ModalMap,
        AnalyticsController, ModalCoupon, ModalTip,
        LeftMenu, MenuItems
    },
    data() {
        return {
			//@data loading <Boolean> indicador si estan cargando los datos del ecommerce
            loading: true,
            local: null,
            menu: null,
            searchItem: '',
            searchFocused: false,
            dictItems: {},
            carousel: null,
            categories: {},
            itemPriceDisplay: 'ITEM_PRICE',
            anticipationFilter: false,
            itemImageSize: 'DEFAULT',
            modalItem: null,
            menuDisplay: 'ALL',
            itemDetails: true,
        }
    },
    mounted() {
        var momentES = moment();
        momentES.locale('es');
        if(typeof data == 'undefined')
        {
            //load data from query
            axios.get(`data`).then(res => {
                this.InitialSetup(res.data);    
            }).catch(err => {
                this.loading = 'ERROR';
            });
        }
        else
        {
            //load data embeded on page
            this.InitialSetup(data.data);
        }

    },
    methods: {
        InitialSetup(data)
        {
            this.timestamp = data.timestamp;
            this.today = moment.unix(this.timestamp).format('dddd').toLowerCase();

            this.local = data.local;
            this.menu = data.menu;
            this.instagramImages = data.ig_images;
            this.schedules = data.open_at;
            this.delay = data.delay;
            this.format_hour = data.format_hour;
            this.Util.setFormatHour(this.format_hour);
            this.format_range = data.format_range;
            this.defaultSale = data.default_sale;
            this.orderOptions = data.order_options;
            this.paymentMethods = data.payment_methods;

            this.minimun = data.delivery_minimun;
            this.recommendProductsConfig = data.recommend_products_active;
            this.banner = data.banner;
            this.ecommerceType = data.ecommerce_type;
            this.shipmentType = data.shipment_type;
            this.canSchedule = this.ecommerceType == 'RESTAURANT' ? data.accepts_scheduled : this.shipmentType !== 'AUTO';
            document.body.classList.add(this.ecommerceType);
            this.moduleCoupons = data.coupons;
            this.disabledUntil = data.disabled_until;
            this.carousel = data.carousel;
            this.topItems = data.popular;
            this.customization = data.customization;
            this.tipsEnabled = data.tips;
            this.defaultTip = data.default_tip;
            if(data.categories) this.categories = data.categories;
            this.timePatch = data.time_patch;
            if(!isNaN(data.min_scheduled_time_today)) this.scheduledMinTime = data.min_scheduled_time_today;
            if(data.item_price_display) this.itemPriceDisplay = data.item_price_display;
            if(data.menu_display) this.menuDisplay = data.menu_display;
            if('item_details' in data) this.itemDetails = data.item_details;

            if(this.orderOptions.delivery && this.orderOptions.takeaway){
                this.type = this.defaultSale == 'DELIVERY'? 'DELIVERY' : 'TAKEAWAY';
            }else{
                if(this.orderOptions.delivery) this.type = 'DELIVERY';
                else if(this.orderOptions.takeaway) this.type = 'TAKEAWAY';
            }


            this.menu.forEach(section => {
                section.items.forEach(item => {
                    if(section.title != 'Productos con anticipación'){
                        item.section = section.title;
                        this.dictItems[item.id] = item;
                        this.dictItems[item.id].section_hide_delivery = section.hide_delivery;
                        this.dictItems[item.id].section_hide_takeaway = section.hide_takeaway;
                    }
                });
            });       

            this.Util.ApplyCustomization(data.customization);
            if(data.customization && data.customization.item_image_size) this.itemImageSize = data.customization.item_image_size;

            this.$nextTick(() => {
                //Google Analytics
                this.$refs.analytics.SetupGoogleAnalytics(data.google_analytics);

                //var facebookPixelID = '539373001231229';
                this.$refs.analytics.SetupFacebookPixel(data.facebook_pixel, data.facebook_access_token);
                this.loading = false;

                const currentPath = window.location.pathname;
                const itemTitle = decodeURIComponent(currentPath.substring(1));
                if(itemTitle){
                    let foundItem = null;
                    for (const section of Util.Copy(this.filteredMenu)) {
                        foundItem = section.items.find(item => item.title === itemTitle.trim());
                        if (foundItem) break;
                    }

                    if(foundItem && !foundItem.unavailable)
                        this.OpenModalItem(foundItem)
                    //this.Error('Producto no disponible');
                }            

            })
            
        },
        OpenModalItem(item) {
            this.Util.ClearLink();

            const queryParams = window.location.search;

            const newUrl = `${window.location.origin}${window.location.pathname}${encodeURIComponent(item.title)}%20${queryParams}`;

            history.pushState(null, '', newUrl);
            
            this.modalItem = item;
            this.$refs.analytics.Trigger('view_item', item);
        }
    },
    computed: {
        filteredMenu(){
            if(!this.menu) return;

            let ret = Util.Copy(this.menu);

            if(this.anticipationFilter){
                ret = ret.map(section => ({
                    ...section,
                    items: section.items.filter(item => item.anticipation && item.anticipation_time != null)
                }));
            }
            return ret;
        },
        //para ver si existen items con reserva y mostrar/ocultar el filtro
        itemsWithAnticipation() {
            let ret = [];
            for(let key in this.dictItems)
            {
                if(this.dictItems[key].anticipation && this.dictItems[key].anticipation_time > 0) 
                    ret.push(this.dictItems[key]);
            }
            return ret;
        },
    },
}
</script>

<style lang="scss">

</style>